<template>
    <div>
        <section id="box-titulo-pagina" class="mb-4" :class="{'gray': scrollPosition > 50}">
            <div class="container">            
                <div class="row">
                <div class="col-md-12">
                    <div class="rutas text-end mb-3">
                        <slot name="area-rutas"></slot>
                    </div>
                </div>
                <div class="col-md-12">
                    <slot name="area-titulo"></slot>                    
                </div>
                </div>            
            </div>
        </section>
    </div>
</template>
<script>

export default {
    data() {
        return {
        scrollPosition: null
        }
    },
    methods: {
        updateScroll() {
        this.scrollPosition = window.scrollY;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
}
</script>